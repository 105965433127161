import styled from "styled-components";

export const HeaderWrpr = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
    align-items: center;
    background-color: ${({theme})=>theme.headerBackground};
    position: fixed;
    z-index: 1000;
`;
export const MainHeading = styled.h1`
    display: inline;
    margin: 0;
    font-family: Crimson Text;
    padding: 2rem 5.75rem;
    font-size: 2.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    background-image: ${({ theme }) => theme.textActive};
    -webkit-background-clip: text; /* For Webkit browsers */
    background-clip: text;
    color: transparent;
    cursor: pointer;

    @media (min-width: 0px) and (max-width: 425px) {
        font-size: 1.3rem;
        padding: 1rem 2rem;
    }

    @media (min-width: 1024px) and (max-width: 1200px){
        font-size: 2rem;
    }
`;

export const SubHeadingContainer = styled.div`
    height: 100%;
    display: flex;
    gap: 2.69rem;
    padding: 2rem 5.75rem;

    @media (max-width: 768px) {
        display: none;
    }
`;

export const SubHeadings = styled.h3`
    color: ${({theme})=> theme.textDefault};
    font-size: 2rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
    margin: 0;
    cursor: pointer;

    &:hover {
        background-image: ${({ theme }) => theme.textActive};
        -webkit-background-clip: text; /* For Webkit browsers */
        background-clip: text;
        color: transparent;
    }
`;

export const ThemeIconWrpr = styled.div`
    height: 2rem;
    width: 2rem;
    cursor: pointer;
`;

export const OptionsWrpr = styled.div`
   display: none;
    @media (max-width: 768px) {
        padding: 2rem;
        height:3rem;
        width: 3rem;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
    }
`;
