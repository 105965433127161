import React from 'react'
import { MainWrpr, Text } from './index.sc'
import AppHeading from '../../Components/Header/Index'

export default function BlogsPage() {
  return (
    <>
    <AppHeading/>
    <MainWrpr>
        <Text>No blogs to show right now, visit back in some time</Text>
    </MainWrpr>
    </>
  )
}
