import React from 'react'
import { CrossIconWrpr, SliderMain, SliderWrpr, SubContainer } from './index.sc'
import { ReactComponent as CrossIcon } from '../../Assets/Images/svgs/CrossIcon.svg';
import { SubHeadings, ThemeIconWrpr } from '../Header/index.sc';
import { useNavigate } from 'react-router';
import { ReactComponent as Moon} from '../../Assets/Images/svgs/moon.svg'
import { ReactComponent as Sun} from '../../Assets/Images/svgs/sun.svg'
import { useDispatch, useSelector } from 'react-redux';
import { updateTheme } from '../../Redux/slices/themeSlice';

export default function Slider({toggleSlidingWrpr}) {

  const dispatch = useDispatch();
  const currentTheme = useSelector(store => store.theme.theme);

  const toggleTheme = () => {
    const newTheme = currentTheme === 'light' ? 'dark' : 'light';
    dispatch(updateTheme({ theme: newTheme }));
  };

  const navigate = useNavigate();

  return (
    <SliderWrpr>
      <SliderMain>
        <CrossIconWrpr>
          <CrossIcon onClick={toggleSlidingWrpr}/>
        </CrossIconWrpr>
        <SubContainer>
          <SubHeadings onClick={() => {navigate('/');}}>Home</SubHeadings>
          <SubHeadings onClick={() => {navigate('/Blogs');}}>Blogs</SubHeadings>
          <SubHeadings onClick={() => {navigate('/Films');}}>Films</SubHeadings>
          <SubHeadings onClick={() => {navigate('/AboutMe');}}>About Me</SubHeadings>
          <ThemeIconWrpr onClick={toggleTheme}>
            {currentTheme === 'light' ? <Moon /> : <Sun />}              
            </ThemeIconWrpr>
        </SubContainer>
      </SliderMain>
    </SliderWrpr>
  )
}
