import React from 'react'
//
import { AnimationWrpr, Loader } from './index.sc'

export default function PageLoading() {
  return (
    <AnimationWrpr>
      <Loader></Loader>
    </AnimationWrpr>
  )
}
