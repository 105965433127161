import styled from "styled-components";

export const MainWrpr = styled.div`
    display: flex;
    gap: 3rem;
    width: 100vw;
    justify-content: center;
    align-items: center;
    background-color: ${({theme})=> theme.background};
    height: 100vh;

    @media (min-width: 1024px) and (max-width: 1200px){
        gap: 1rem;
    }

    @media (max-width: 768px) and (min-width: 426px) {
        flex-direction: column-reverse;
        gap: 3rem;
        padding-top: 10rem;
        padding-bottom: 5rem;
        height: 100%;
    }

    @media (min-width: 0px) and (max-width: 425px){
        flex-direction: column-reverse;
        gap: 3rem;
        padding-top: 8rem;
        padding-bottom: 2rem;
        height: 100%;
    }
`;

export const TextWrpr = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 60vw;

    @media (max-width: 768px) and (min-width: 426px) {
       width: calc(100% - 2rem);
       padding: 1rem;
    }

    @media (min-width: 0px) and (max-width: 425px){
        width: calc(100% - 2rem);
       padding: 1rem;
    }

`;

export const ImageWrpr = styled.div`
    height: 45rem;
    width: 20rem;
    border-radius: 0.5rem 80% 0.5rem 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:hover{
        transform: scale(1.15);
        transition: transform 0.3s ease-in-out;
    }

    @media (max-width: 768px) and (min-width: 426px) {
       height: 25rem;
    }

    @media (min-width: 0px) and (max-width: 425px){
       height: 25rem;
    }
`;

export const Text = styled.div`
    color: ${({theme})=> theme.textDefault};
    font-size: 2rem;
    font-weight: ${({theme})=> theme.textWeight};
    @media (min-width: 0px) and (max-width: 425px){
        font-size: 1.25rem;
    }

    @media (min-width: 1024px) and (max-width: 1200px){
        font-size: 1.5rem;
    }

`;