import React from 'react'
import MyPageroutes from './Utils/routes'
import { ThemeProvider } from 'styled-components'
import { theme } from './Assets/Themes/theme'
import { useSelector } from 'react-redux'

export default function App() {
  const selectedTheme = useSelector((store) => {
    return store?.theme.theme || {};
  });

  return (
    <ThemeProvider theme={{ theme, ...theme[selectedTheme] }}>
      <MyPageroutes />
    </ThemeProvider>
  )
}
