import React from 'react'
import AppHeading from '../../Components/Header/Index'
import { Heading, LeftMasterDiv, MainWrpr, TextWrpr, Text, Image } from './index.sc'
import { ReactComponent as DigitalArt1 } from '../../Assets/Images/svgs/DigitalArt1.svg';

const BulletPointsArray = [
  "I’m a 19 year old, IIT Madras Student",
  "A self taught developer, and part time Cinematographer",
  "I mainly work with MERN Stack on a daily basis",
  "Currently in my hustle zone",
]

export default function LandingPage() {
  return (
    <>
      <AppHeading/>
      <MainWrpr>
        <LeftMasterDiv>
          <Heading>Ohh Hi! &#128075;</Heading>
          <TextWrpr>
            {BulletPointsArray.map((item)=>
            (<Text>{item}</Text>)
            )}
          </TextWrpr>
        </LeftMasterDiv>
        <Image>
          <DigitalArt1 className="DigitalArt1Hover"/>
        </Image>
      </MainWrpr>
    </>
  )
}
