import { configureStore } from "@reduxjs/toolkit";
import themeSlice from './slices/themeSlice';

const storedUserData = localStorage.getItem('data');
const storedToken = localStorage.getItem('token');

const initialUserState = {
  data: storedUserData ? JSON.parse(storedUserData) : null,
  token: storedToken || '',
};

const store = configureStore({
  reducer: {
    theme: themeSlice,
  },
  preloadedState: {
    user: initialUserState,
  },
});

export default store;