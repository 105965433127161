import React from 'react'
import AppHeading from '../../Components/Header/Index'
import { CardMainWrpr, CardWrpr, Heading, MainWrpr } from './index.sc'
import YTCards from '../../Components/YtCards'

const CardArray = [
  {
    heading:'Republic Day 2019 | Short Film | JB Academy',
    description: "Celebrate the spirit of patriotism with our heartfelt portrayal of Republic Day at JB Academy. Join us on a captivating journey through emotions, pride, and the essence of our nation's unity. This short film embodies the fervor and significance of this auspicious day, encapsulating the values and glory of our country's heritage. Experience the pride of being an Indian through the lens of JB Academy's tribute to Republic Day 2019.",
    link: 'https://www.youtube.com/embed/h5pcXS_Ni4o?si=Ba8UlC6nRU_jyBdS'
  },
  {
    heading:'This is how I made MY FIRST *AWARD WINNING* FILM | Behind the scenes',
    description: "Step behind the camera and delve into the creative process! Join me on an exclusive journey, peering into the intricate making of my first award-winning film. Uncover the challenges, triumphs, and sheer dedication that went into crafting this cinematic masterpiece. This captivating behind-the-scenes glimpse reveals the passion, determination, and sheer artistry that culminated in an unforgettable cinematic experience. Experience the magic behind the lens, offering a unique perspective on the art of filmmaking and the journey to success.",
    link: 'https://www.youtube.com/embed/Gz9AbcH6jiI?si=5vpcQH8OWcarvhTo'
  },
  {
    heading:"Best Teacher's Day *BTS EVER* | Dhaval",
    description: "Join me on a heartwarming journey celebrating the essence of Teacher's Day! Delve into the heartwarming and entertaining behind-the-scenes moments that made this Teacher's Day tribute an unforgettable experience. This exclusive peek captures the camaraderie, laughter, and heartfelt emotions shared during the creation of this special tribute. Witness the dedication and admiration as we honor the mentors who shape our lives. Experience the joyous moments and heartfelt gestures that make this behind-the-scenes footage the best Teacher's Day tribute ever!",
    link: 'https://www.youtube.com/embed/M3DhyHKkaws?si=QVwSaGO4fVOm5G7B'
  },
  {
    heading: 'CMS invited me over ! Dhaval J Prasad',
    description: "Embark on an enthralling journey as I am invited to CMS, sharing insights, knowledge, and experiences. Join me in this captivating narrative showcasing the vibrant atmosphere and engaging moments during my visit to CMS. Experience the exchange of ideas, enthusiasm, and the enriching interactions that made this visit a remarkable experience. This behind-the-scenes glimpse captures the essence of collaboration, learning, and the spirit of connectivity, fostering a memorable and enlightening occasion at CMS.",
    link: 'https://www.youtube.com/embed/-fc7Ywo7zgY?si=hJDYe13kh76qXOTK'
  },
  {
    heading: 'CORONA GO, JAI HO ( OFFICIAL VIDEO ) | DHAVAL & KARTIK MALHOTRA | J B ACADEMY Stay home, stay safe.',
    description: "Join the impactful journey through 'CORONA GO, JAI HO,' a collaborative effort by Dhaval and Kartik Malhotra from J B ACADEMY. This official video is a powerful anthem advocating resilience and safety during challenging times. Experience the vibrancy, spirit, and unity reflected in this spirited call-to-action against COVID-19. Through music and visuals, witness the message of hope, perseverance, and the collective determination to overcome adversity. Embrace the essence of solidarity and the plea to stay home and stay safe amidst the global pandemic.",
    link: 'https://www.youtube.com/embed/hv4sOPaY9Lk?si=nDHphqY4CIKA2SnN'
  }
]

export default function FilmsPage() {

  return (
    <>
        <AppHeading/>
        <MainWrpr>
            <Heading>
              Yes! Sometimes I make  Films 🎬, most of them are award winning 🏆, some are not 
              <br/>
              BEST OF THEM ARE -
            </Heading>
            <CardMainWrpr>
              <CardWrpr>
                {CardArray.map((item)=>
                (<YTCards
                  heading={item.heading}
                  description={item.description}
                  link={item.link}
                />))}
              </CardWrpr>
            </CardMainWrpr>
        </MainWrpr>
    </>
  )
}
