import styled from "styled-components";

export const ErrorPageWrpr = styled.div`
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: center;
    align-items: center;
`;

export const Heading = styled.h1`
    font-size: 1.5rem;
    color: red;
`;

export const Text = styled.p`
    font-size: 1rem;
    color: red;
`;