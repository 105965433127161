import styled from "styled-components";

export const MainWrpr = styled.div`
    width: 100vw;
    height: 100vh;
    background-color: ${({theme})=>theme.background};
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    /* padding-top: 8rem; */
    gap: 1rem;
`;

export const Heading = styled.h1`
    margin: 0;
    font-size: 2rem;
    font-weight: ${({theme})=>theme.textWeight};
    color: ${({theme})=>theme.textDefault};
    text-align: center;
    width: 100%;
    margin-top: 8rem;

    @media (min-width: 426px) and (max-width: 768px){
        font-size: 1.4rem;
    }

    @media (min-width: 0px) and (max-width: 425px){
        font-size: 1rem;
    }

`;

export const CardMainWrpr = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const CardWrpr = styled.div`
    width: auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
    height: calc(100vh - 18rem);
    overflow: scroll;

    @media (min-width: 0px) and (max-width: 768px) {
        grid-template-columns: 1fr;
    }

     /* WebKit (Chrome, Safari) */
    &::-webkit-scrollbar {
        display: none; /* Hide scrollbar */
    }

    /* Firefox */
    scrollbar-width: none; /* Hide scrollbar */
    
    /* Internet Explorer and Edge */
    -ms-overflow-style: none; /* Hide scrollbar */
`;