import styled from "styled-components";

export const MainWrpr = styled.div`
    display: flex;
    gap: 3rem;
    width: 100vw;
    height: 100vh;
    justify-content: center;
    align-items: center;
    background-color: ${({theme})=> theme.background};

    @media (min-width: 1024px) and (max-width: 1200px){
        gap: 1rem;
    }

    @media (max-width: 768px) and (min-width: 426px) {
        flex-direction: column-reverse;
        gap: 3rem;
        padding-top: 10rem;
        padding-bottom: 5rem;
    }

    @media (min-width: 0px) and (max-width: 425px){
        flex-direction: column-reverse;
        gap: 2rem;
        padding-top: 4rem;
        padding-bottom: 0rem;
    }
`;

export const LeftMasterDiv = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.38rem;
    cursor: pointer;
`;

export const Heading = styled.h1`
    margin: 0;
    color: ${({theme})=> theme.textDefault};
    font-weight: ${({theme})=> theme.boldText};
    font-size: 3rem;

    @media (min-width: 0px) and (max-width: 425px){
        font-size: 1.5rem;
        padding: 1rem;
    }

    @media (min-width: 1024px) and (max-width: 1200px){
        font-size: 2rem;
    }
`;

export const TextWrpr = styled.ul`
    margin: 0;
`;

export const Text = styled.li`
    color: ${({theme})=> theme.textDefault};
    font-size: 2rem;
    font-weight: ${({theme})=> theme.textWeight};
    @media (min-width: 0px) and (max-width: 425px){
        font-size: 1.25rem;
    }

    @media (min-width: 1024px) and (max-width: 1200px){
        font-size: 1.5rem;
    }
`;

export const Image = styled.div`
    height: 25rem;
    width: 25rem;
    cursor: pointer;
    border-radius: 2rem;
    overflow: hidden;
        .DigitalArt1Hover:hover {
            transform: scale(1.05);
            transition: transform 0.3s ease-in-out;
        }

    @media (min-width: 1024px) and (max-width: 1200px){
        height: 20rem;
        width: 20rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    @media (min-width: 0px) and (max-width: 425px){
        height: 18rem;
        width: 18rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }
`;