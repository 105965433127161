import styled, {keyframes} from "styled-components";

const slideAnimation = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const SliderWrpr = styled.div`
  height: 100%;
  width: 100vw;
  position: absolute;
  z-index: 1000;
  background-color: rgba(128,128,128, 0.4);
  display: flex;
  overflow: hidden;
`;

export const SliderMain = styled.div`
  height: 100vh;
  width: 70vw;
  background-color: ${({theme})=> theme.background};
  margin-left: auto;
  animation: ${slideAnimation} 0.33s ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 1rem;
  padding: 1rem;
`;

export const CrossIconWrpr = styled.div`
  width: 3rem;
  height: 3rem;
`;

export const SubContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
  justify-content: space-between;
  align-items: flex-start;
`;

