import React, { useState } from 'react'
import { HeaderWrpr, MainHeading, OptionsWrpr, SubHeadingContainer, SubHeadings, ThemeIconWrpr } from './index.sc'
import Slider from '../SlidingSection';
import { ReactComponent as Options } from '../../Assets/Images/svgs/options.svg'
import { ReactComponent as Moon} from '../../Assets/Images/svgs/moon.svg'
import { ReactComponent as Sun} from '../../Assets/Images/svgs/sun.svg'
import { useDispatch, useSelector } from 'react-redux';
import { updateTheme } from '../../Redux/slices/themeSlice';
import { useNavigate } from 'react-router';

export default function AppHeading() {

  const dispatch = useDispatch();
  const currentTheme = useSelector(store => store.theme.theme);

  const toggleTheme = () => {
    const newTheme = currentTheme === 'light' ? 'dark' : 'light';
    dispatch(updateTheme({ theme: newTheme }));
  };

  const [showSlidingWrpr, setShowSlidingWrpr] = useState(false);

  const toggleSlidingWrpr = () => {
    setShowSlidingWrpr(!showSlidingWrpr);
  };

  const navigate = useNavigate();

  return (
    <>
      <HeaderWrpr>
          <MainHeading onClick={() => {navigate('/');}}>
              Dhaval J Prasad
          </MainHeading>
          <SubHeadingContainer>
            <SubHeadings onClick={() => {navigate('/Blogs');}}>Blogs</SubHeadings>
            <SubHeadings onClick={() => {navigate('/Films');}}>Films</SubHeadings>
            <SubHeadings onClick={() => {navigate('/AboutMe');}}>About Me</SubHeadings>
            <ThemeIconWrpr onClick={toggleTheme}>
            {currentTheme === 'light' ? <Moon /> : <Sun />}              
            </ThemeIconWrpr>
          </SubHeadingContainer>
          <OptionsWrpr onClick={()=> {toggleSlidingWrpr();}}>
            <Options/>
          </OptionsWrpr>
      </HeaderWrpr>
      {showSlidingWrpr && <Slider toggleSlidingWrpr={toggleSlidingWrpr}/>}      
    </>
  )
}
