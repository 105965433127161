import React from 'react'
import { MasterDiv } from './index.sc'
import YTCards from '../YtCards'

export default function TestPage() {
  return (
    <MasterDiv>
      <YTCards/>
    </MasterDiv>
  )
}
