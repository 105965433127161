import React, {Suspense} from "react";
import { Route, Routes } from "react-router-dom";
import PageLoading from "../Components/Loader";
import ErrorPage from "../Components/Error Page";
import TestPage from "../Components/Test page";

// main pages import

import LandingPage from "../Pages/Landing-Page";
import FilmsPage from "../Pages/Films-Page";
import AboutMePage from "../Pages/AboutMe-Page";
import BlogsPage from "../Pages/Blogs-Page";


export const navMenu = [
    {
        label: 'Landing Page',
        path: '/',
        element:(
            <Suspense
                fallback={
                    <PageLoading/>
            }>
            <LandingPage/>
            </Suspense>
        ),
        errorElement: <ErrorPage/>,
    },
    {
        label: 'About Me',
        path: '/Films',
        element: (
            <Suspense
                fallback={
                    <PageLoading/>
            }>
                <FilmsPage/>
            </Suspense>
        )
    },
    {
        label: 'Blogs',
        path: '/Blogs',
        element: (
            <Suspense
                fallback={
                    <PageLoading/>
            }>
                <BlogsPage/>
            </Suspense>
        )
    },
    {
        label: 'About Me',
        path: '/AboutMe',
        element: (
            <Suspense
                fallback={
                    <PageLoading/>
            }>
                <AboutMePage/>
            </Suspense>
        )
    },
    {
        label: 'Test Component',
        path: '/test-component',
        element: (
            <Suspense 
                fallback = {
                    <PageLoading/>
            }>
            <TestPage/>
            </Suspense>
        ),
        errorElement: <ErrorPage/>,
    }
]

export default function MyPageroutes() {
  return (
    <Routes>
        {navMenu
            .map((ele, i) => (
                <Route
                    key={`${ele.path}-${i}}`}
                    path={ele.path}
                    element={ele.element}
                    errorElement={ele.errorElement}
                />
            ))}
    </Routes>
  )
}
