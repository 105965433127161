import styled from "styled-components";

export const MainWrpr = styled.div`
    width: 100vw;
    height: 100vh;
    background-color: ${({theme})=>theme.background};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
`;

export const Text = styled.h3`
    margin: 0;
    font-size: 1rem;
    font-weight: 400;
    color: ${({theme})=> theme.textDefault};
`;