import React from 'react'
// import { useRouteError } from 'react-router-dom'
import { ErrorPageWrpr, Heading, Text } from './index.sc'

export default function ErrorPage() {
    // const error = useRouteError();
  return (
    <ErrorPageWrpr>
        <Heading>
            Oops!
        </Heading>
        
        <Text>Sorry, an unexpected error has occurred.</Text>
        {/* <Text>{error.statusText || error.message}</Text> */}
    </ErrorPageWrpr>
  )
}
