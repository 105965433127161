import React from 'react'
import AppHeading from '../../Components/Header/Index'
import { ImageWrpr, MainWrpr, Text, TextWrpr } from './index.sc'
import { ReactComponent as DigiBoy } from '../../Assets/Images/svgs/my-art.svg'

const TextArray = [
  `So you're eager to know about me? \u{1F601} Okay, let's start`,
  "Hey there!",
  "I'm currently a Data Science enthusiast pursuing my bachelor's degree at IIT Madras. Beyond my academic journey, I'm also a proficient MERN Stack developer. This website serves as a digital journal of my interests, which span from technology and football to mathematics, film making, spirituality, and the intricacies of life itself.",
  "A special shoutout to Pratham Prasoon for inspiring this wonderful project!",
]

export default function AboutMePage() {
  return (
    <>
     <AppHeading/>
     <MainWrpr>
        <TextWrpr>
          {TextArray.map((item)=>{
            return <Text>{item}</Text>
          })}
        </TextWrpr>
        <ImageWrpr>
          <DigiBoy/>
        </ImageWrpr>
     </MainWrpr>
    </>
  )
}
