import styled from "styled-components";

export const CardWrpr = styled.div`
    height: 400px;
    width: 500px;
    border-radius: 0.625rem;
    background-color: ${({theme})=> theme.cardBackground};
    cursor: pointer;

    @media (min-width: 0px) and (max-width: 425px){
        width: 300px;
        height: 200px;
    }
`;

export const VideoPlayer = styled.iframe`
    height: 250px;
    width: 100%;
    object-fit: cover;
    border-radius: 0.625rem 0.625rem 0rem 0rem;
    border: 0;

    @media (min-width: 0px) and (max-width: 425px){
        height: 125px;
    }
`;

export const CardHeading = styled.h2`
    margin: 0;
    color: #000;
    padding: 0rem 0.5rem;
    font-size: 1.5rem;
    overflow-y: hidden;
    height: 1.75rem;

    @media (min-width: 0px) and (max-width: 425px){
        font-size: 1rem;
        height: 1.2rem;
    }
`;

export const CardDiscription = styled.p`
    padding: 0.5rem;
    margin-top: 1rem;
    margin: 0;
    overflow: scroll;
    height: 80px;

    @media (min-width: 0px) and (max-width: 425px){
        font-size: 0.8125rem;
        margin: 0;
        padding: 0.2rem;
        height: 40px;
    }

    /* WebKit (Chrome, Safari) */
    &::-webkit-scrollbar {
        display: none; /* Hide scrollbar */
    }

    /* Firefox */
    scrollbar-width: none; /* Hide scrollbar */
    
    /* Internet Explorer and Edge */
    -ms-overflow-style: none; /* Hide scrollbar */
`;