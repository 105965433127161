export const theme = {
    light: {
        background: '#ffffff',
        textDefault: '#000000',
        primary: '#FFC0DB',
        secondary: '#6945CB',
        textActive: 'linear-gradient(90deg, #6945CB 0%, #FFC0DB 100%)',
        hoverState: 'linear-gradient(90deg, #6945CB 0%, #FFC0DB 100%)',
        desktopHeading: '700',
        boldText: '600',
        textWeight: '400',
        headerBackground: '#ffffff',
        cardBackground: '#D9D9D9',
    },
    dark: {
        background: '#252525',
        textDefault: '#ffffff',
        primary: '#FFC0DB',
        secondary: '#6945CB',
        textActive: 'linear-gradient(90deg, #6945CB 0%, #FFC0DB 100%)',
        hoverState: 'linear-gradient(90deg, #6945CB 0%, #FFC0DB 100%)',
        desktopHeading: '700',
        boldText: '600',
        textWeight: '400',
        headerBackground: '#131313',
        cardBackground: '#ffffff'
    }
};
