import React from 'react'
import { CardDiscription, CardHeading, CardWrpr, VideoPlayer } from './index.sc'

export default function YTCards({heading, description, link}) {

  return (
    <CardWrpr>
      {/* <ImageWrpr src={image}>
      </ImageWrpr> */}
      <VideoPlayer src={link} title={heading}></VideoPlayer>
      <CardHeading>{heading}</CardHeading>
      <CardDiscription>{description}</CardDiscription>
    </CardWrpr>
  )
}
